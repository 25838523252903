import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from "react";
import { SectionTitle } from "../../components/section-title";
import { InputField } from "../../components/input-field";
import { CustomButton } from "../../components/custom-button/CustomButton";
import { PinModal } from "../../components/pin-modal/PinModal";
import { useHistory } from "react-router-dom";
import authContext from "../../context/auth-context";
import { useDispatch, useSelector } from "react-redux";
import { actions as scanResultActions } from "../../pages/redux/ScannerDetailsRedux";
import { useDebounce } from "use-debounce/lib";
import { useDispatchRfidQrResult } from "./hook/useDispatchRfidQrResult";
import { actions } from "../redux/ScannerRedux";
import { Footer } from "../../components/footer/Footer";
export const ScannerValidatorRFIDPage = () => {
  const [rfidValue, setRfidValue] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStartScanning, setIsStartScanning] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const show = useContext(authContext);
  const [debouncerfid] = useDebounce(rfidValue, 300);
  const listnerRef = useRef(null);
  const [rfidError, setRFIDError] = useState();
  const scanner = useSelector((state) => state.scanner.scanner);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const { getData } = useDispatchRfidQrResult();
  const entryPoint = useSelector((state) => state.scanner.entryPoint);
  const [error, setError] = useState();

  useEffect(() => {
    dispatch(scanResultActions.clearResult());
  }, [dispatch]);

  useEffect(() => {
    show.setLogoutCtxt(true);
    show.setBackButtonCtxt(false);
  }, [show]);

  useEffect(() => {
    document.getElementById("mainBox").style.overflowY = "hidden";
    document.body.style.overflowY = "hidden";
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsStartScanning(true);
      clearTimeout(timer);
    }, 200);

    if (listnerRef.current) {
      const body = document.getElementById("mainBox");
      const element = document.getElementById("rfid-input");
      if (element) element.focus();
      listnerRef.current.addEventListener("click", () => {
        if (element) element.focus();
      });
      listnerRef.current.addEventListener("touchend", () => {
        if (element) element.focus();
      });

      if (body) {
        body.addEventListener("click", () => {
          if (element) element.focus();
        });
        body.addEventListener("touchend", () => {
          if (element) element.focus();
        });
      }
    }
  }, [listnerRef]);

  useEffect(() => {
    if (!selectedEvent) {
      history.replace("/events");
    }
    if (
      !entryPoint &&
      scanner &&
      scanner.data &&
      scanner.data.type === "scanner"
    ) {
      history.replace("/points");
    }
  }, [history, scanner, selectedEvent, entryPoint]);

  const handleChangInput = useCallback(
    (event) => {
      if (isStartScanning) setRfidValue(event.target.value);
    },
    [isStartScanning]
  );

  useEffect(() => {
    if (isSubmitting) {
      setError(null);
      let hexString = "";
      if (!debouncerfid.includes("!")) hexString = decimalToHexa(debouncerfid);
      else
        hexString = decimalToHexa(debouncerfid)
          .match(/.{1,2}/g)
          .reverse()
          .toString()
          .replaceAll(",", "");
      let final_code =
        hexaDecimalToDeicmal(hexString).toString() !== "NaN"
          ? hexaDecimalToDeicmal(hexString).toString()
          : debouncerfid;

      if (scanner && scanner.data && final_code && selectedEvent) {
        const _data = {
          ticketCode: final_code.trim(),
          gateCode: scanner.data.code.trim(),
          isCheckedIn: !entryPoint
            ? true
            : entryPoint.name === "Entry Point"
            ? true
            : false,
          eventCode: selectedEvent.code.trim(),
          rfid: true,
        };

        getData({
          type: "rfid",
          dataBody: _data,
          debounceCode: final_code.trim(),
          setIsSubmitting,
          setResult: () => setRfidValue(""),
          entryPoint,
          history: history,
          goto: "/scanner-result",
        });
      }
    }
  }, [
    isSubmitting,
    debouncerfid,
    history,
    entryPoint,
    selectedEvent,
    scanner,
    getData,
    dispatch,
  ]);

  const handleCheckRFID = useCallback(async () => {
    if (debouncerfid && debouncerfid.length >= 7) {
      setIsSubmitting(true);
    } else {
      setError("Min characters is 7");
    }
  }, [debouncerfid, setError]);

  useEffect(() => {
    if (debouncerfid && debouncerfid.length >= 7) handleCheckRFID();
    if (debouncerfid && debouncerfid.length < 7) setRFIDError("Invalid code");
  }, [debouncerfid, handleCheckRFID]);

  const footerTitle = useMemo(() => {
    if (scanner && scanner.data && selectedEvent && entryPoint)
      return `${scanner.data.name} / ${selectedEvent.name} / ${entryPoint.name}`;
    if (scanner && scanner.data && selectedEvent && !entryPoint)
      return `${scanner.data.name} / ${selectedEvent.name}`;
  }, [scanner, selectedEvent, entryPoint]);

  const handlePinSubmit = useCallback(() => {
    setTimeout(() => {
      if (
        scanner &&
        scanner.data &&
        (scanner.data.type === "validator" || scanner.data.type === "qa")
      ) {
        history.push("/events");
      } else {
        history.push("/points");
      }
    }, 500);
  }, [history, scanner]);

  return (
    <>
      <div
        className="position-absolute top-50 start-50 translate-middle "
        ref={listnerRef}
      >
        <div className="d-flex flex-column">
          <SectionTitle name="Scan RFID" />

          <div
            className="px-0 mx-0 w-100 text-center"
            style={{
              maxHeight: "60vh",
              overflowY: "auto",
            }}
          >
            <div className="mt-5 w-100 ">
              <InputField
                disabled={isSubmitting}
                id="rfid-input"
                type="text"
                name="rfid"
                value={rfidValue}
                placeholder="RFID Code"
                onChange={(e) => handleChangInput(e)}
                errorMessage={error}
                isTouched={true}
              />

              {rfidError && (
                <p
                  className="text-center mt-2"
                  style={{
                    color: "red",
                  }}
                >
                  {rfidError}
                </p>
              )}

              <div className="text-center mt-3">
                <CustomButton
                  disabled={!rfidValue || isSubmitting}
                  isSubmitting={isSubmitting}
                  type="button"
                  clicked={handleCheckRFID}
                  label="submitGate"
                  title="Next"
                >
                  Next
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PinModal
        show={showCode}
        handleClose={() => {
          document.getElementById("rfid-input").focus();
          setShowCode(false);
        }}
        onSubmit={handlePinSubmit}
      />

      <div
        className="position-fixed bottom-0 end-0 m-3 scan-with-qrcode-or-rfid"
        onClick={() => {
          dispatch(actions.scannerPath.set("/qrscan"));
          setTimeout(() => {
            history.push("/qrscan");
          }, 500);
        }}
      >
        Scan with QrCode
      </div>

      <Footer
        path={footerTitle}
        onClick={() => {
          setTimeout(() => {
            setShowCode(true);
          }, 500);
          document.getElementById("rfid-input").blur();
        }}
      />
    </>
  );
};

const decimalToHexa = (number) => {
  return parseInt(number).toString(16);
};

const hexaDecimalToDeicmal = (code) => {
  return parseInt(code, 16);
};
