import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useBreakPoint } from "../../hook/useBreakPoint";
import clsx from "clsx";
export const BoxNameArrow = ({ marginLeft, code, name, image, onClick }) => {
  const { down } = useBreakPoint();

  const isMobile = useMemo(() => {
    return down("xs");
  }, [down]);
  const imagBox = useMemo(() => {
    if (image) {
      return (
        <img
          className="mr-3"
          src={
            image === "enter"
              ? "./assets/images/EntryPoints.svg"
              : "./assets/images/ExitPoints.svg"
          }
          alt="sds"
        />
      );
    }
    return null;
  }, [image]);
  return (
    <div
      className={clsx("col-12 col-md-10 row mx-0", {
        "mb-2": isMobile,
        "mb-4": !isMobile,
      })}
    >
      <button
        type="forward"
        className={clsx(
          "d-flex justify-content-between  w-100 overflow-hidden cursor-pointer position-relative btn rounded boxWithArrow",
          { "p-2": isMobile, "p-4": !isMobile }
        )}
        aria-label="submitLogIn"
        onTouchStart={() => {
          if (code && onClick) onClick(name, code);
          else onClick && onClick(name);
        }}
        onClick={() => {
          if (code && onClick) onClick(name, code);
          else onClick && onClick(name);
        }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          {imagBox}
        </div>
        <span
          className={clsx("text-light", {
            "fs-6": isMobile,
            "fs-3": !isMobile,
          })}
          style={{
            width: "90%",
            textAlign: "left",
            marginLeft: marginLeft ? "40px" : "0px",
          }}
        >
          {name}
        </span>
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <svg width="24.367" height="24.367" viewBox="0 0 24.367 24.367">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M687.663,352v10.982c0,1.982-.174,3.814-3.255,3.814H672"
              transform="translate(-724.077 238.764) rotate(-45)"
              fill="none"
              stroke="#e1241b"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </svg>
        </div>
      </button>
    </div>
  );
};

BoxNameArrow.propTypes = {
  marginLeft: PropTypes.bool,
  code: PropTypes.string,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  onClick: PropTypes.func,
};

BoxNameArrow.defaultProps = {
  marginLeft: true,
};
