import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { GetEvents, GetScannerByCode, GetBadgeCollection } from "./ScannerCRUD";
export const actionTypes = {
  scanner: {
    execute: "[SCANNER] SCANNER EXECUTE",
    success: "[SCANNER] SCANNER SUCCESS",
    failed: "[SCANNER] SCANNER FAILED",
    reset: "[SCANNER] SCANNER RESET",
  },

  events: {
    execute: "[SCANNER] EVENTS EXECUTE",
    success: "[SCANNER] EVENTS SUCCESS",
    failed: "[SCANNER] EVENTS FAILED",
    reset: "[SCANNER] EVENTS RESET",
  },

  customer: {
    execute: "[SCANNER] CUSTOMER EXECUTE",
    success: "[SCANNER] CUSTOMER SUCCESS",
    failed: "[SCANNER] CUSTOMER FAILED",
    reset: "[SCANNER] CUSTOMER RESET",
  },

  selectedEvent: {
    set: "[SCANNER] SELECTED EVENT SET",
    reset: "[SCANNER] SELECTED EVENT RESET",
  },
  entryPoint: {
    set: "[SCANNER] SELECTED ENTRYPOINT SET",
    reset: "[SCANNER] SELECTED ENTRYPOINT RESET",
  },
  scannerPath: {
    set: "[SCANNER] SELECTED SCANNERPATH SET",
    reset: "[SCANNER] SELECTED SCANNERPATH RESET",
  },
  isRFID: {
    set: "[SCANNER] RFID SET",
  },
};

const initialState = {
  scanner: undefined,
  events: undefined,
  customer: undefined,
  selectedEvent: undefined,
  entryPoint: undefined,
  isRFID: false,
  scannerPath: "",
};
export const reducer = persistReducer(
  {
    storage,
    key: "tedx-scanner",
    whitelist: [
      "scanner",
      "customer",
      "events",
      "selectedEvent",
      "entryPoint",
      "scannerPath",
      "isRFID",
    ],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.scanner.execute: {
        return {
          ...state,
          scanner: {
            isLoading: true,
          },
        };
      }
      case actionTypes.scanner.success: {
        const scanner = action.payload.scanner;
        return {
          ...state,
          scanner: {
            data: scanner,
            isLoading: false,
            error: null,
          },
        };
      }
      case actionTypes.scanner.failed: {
        const error = action.payload;
        return {
          ...state,
          scanner: {
            data: null,
            error: error.response.data.error,
            isLoading: false,
          },
        };
      }
      case actionTypes.scanner.reset: {
        return {
          ...state,
          scanner: {
            data: null,
            isLoading: false,
            error: null,
          },
        };
      }

      case actionTypes.customer.execute: {
        return {
          ...state,
          customer: {
            isLoading: true,
          },
        };
      }

      case actionTypes.customer.success: {
        const customer = action.payload.customer;
        return {
          ...state,
          customer: {
            data: customer,
            isLoading: false,
            error: null,
          },
        };
      }

      case actionTypes.customer.failed: {
        const error = action.payload;
        return {
          ...state,
          customer: {
            data: null,
            error: error.response.data.error,
            isLoading: false,
          },
        };
      }
      case actionTypes.customer.reset: {
        return {
          ...state,
          customer: {
            data: null,
            isLoading: false,
            error: null,
          },
        };
      }

      case actionTypes.events.success: {
        const events = action.payload.events;
        return {
          ...state,
          events: {
            data: events,
            isLoading: false,
            error: null,
          },
        };
      }

      case actionTypes.events.failed: {
        const error = action.payload;
        return {
          ...state,
          events: {
            data: null,
            error: error.response.data.error,
            isLoading: false,
          },
        };
      }

      case actionTypes.events.reset: {
        return {
          ...state,
          events: {
            data: null,
            error: null,
            isLoading: false,
          },
        };
      }

      case actionTypes.selectedEvent.set: {
        const selectedEvent = action.payload;
        return { ...state, selectedEvent };
      }

      case actionTypes.selectedEvent.reset: {
        return { ...state, selectedEvent: null };
      }

      case actionTypes.entryPoint.set: {
        const entryPoint = action.payload;
        return { ...state, entryPoint };
      }
      case actionTypes.entryPoint.reset: {
        return { ...state, entryPoint: null };
      }
      case actionTypes.scannerPath.set: {
        const scannerPath = action.payload;
        return { ...state, scannerPath };
      }

      case actionTypes.isRFID.set: {
        const isRFID = action.payload;
        return { ...state, isRFID };
      }
      default:
        return state;
    }
  }
);

// AFTER EFFECT
export function* saga() {
  yield takeLatest(
    actionTypes.scanner.execute,
    function* afterEffectSaga(action) {
      try {
        const { data } = yield GetScannerByCode(action.payload.code);
        yield put(actions.scanner.searchSuccess(data));
      } catch (e) {
        yield put(actions.scanner.searchFailed(e));
      }
    }
  );

  yield takeLatest(
    actionTypes.events.execute,
    function* afterEffectSaga(action) {
      try {
        const { data } = yield GetEvents(action);
        yield put(actions.events.searchSuccess(data));
      } catch (e) {
        yield put(actions.events.searchFailed(e));
      }
    }
  );

  yield takeLatest(
    actionTypes.customer.execute,
    function* afterEffectSaga(action) {
      try {
        const { data } = yield GetBadgeCollection(action.payload);
        yield put(actions.customer.searchSuccess(data));
      } catch (e) {
        yield put(actions.customer.searchFailed(e));
      }
    }
  );
}

export const actions = {
  scanner: {
    search: (payload) => ({ type: actionTypes.scanner.execute, payload }),
    searchSuccess: (data) => ({
      type: actionTypes.scanner.success,
      payload: { scanner: data },
    }),
    searchFailed: (e) => ({ type: actionTypes.scanner.failed, payload: e }),
    resetScanner: () => ({ type: actionTypes.scanner.reset }),
  },

  events: {
    search: (payload) => ({ type: actionTypes.events.execute, payload }),
    searchSuccess: (data) => ({
      type: actionTypes.events.success,
      payload: { events: data },
    }),
    searchFailed: (e) => ({ type: actionTypes.events.failed, payload: e }),
    resetEvents: () => ({ type: actionTypes.events.reset }),
  },

  customer: {
    search: (payload) => ({ type: actionTypes.customer.execute, payload }),
    searchSuccess: (data) => ({
      type: actionTypes.customer.success,
      payload: { customer: data },
    }),
    searchFailed: (e) => ({ type: actionTypes.customer.failed, payload: e }),
    resetCustomer: () => ({ type: actionTypes.customer.reset }),
  },

  selectedEvent: {
    set: (payload) => ({ type: actionTypes.selectedEvent.set, payload }),
    reset: () => ({ type: actionTypes.selectedEvent.reset }),
  },

  entryPoint: {
    set: (data) => ({
      type: actionTypes.entryPoint.set,
      payload: data,
    }),
    reset: () => ({ type: actionTypes.entryPoint.reset }),
  },

  scannerPath: {
    set: (data) => ({
      type: actionTypes.scannerPath.set,
      payload: data,
    }),
    reset: () => ({ type: actionTypes.scannerPath.reset }),
  },

  isRFID: {
    set: (data) => ({
      type: actionTypes.isRFID.set,
      payload: data,
    }),
  },
};
