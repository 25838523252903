import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { SectionTitle } from "../../components/section-title";
import { InputField } from "../../components/input-field";
import { CustomButton } from "../../components/custom-button/CustomButton";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce/lib";
import { Footer } from "../../components/footer/Footer";
import { actions } from "../redux/ScannerRedux";
import { toasterActions } from "../../_redux/ToasterRedux";
import { useSocketIO } from "../../hook/useSocketIO";
export const BadgeCollectionRFIDPage = () => {
  const [rifdValue, setrfidValue] = useState("");
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStartScanning, setIsStartScanning] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const scanner = useSelector((state) => state.scanner.scanner);
  const [debouncerfid] = useDebounce(rifdValue, 300);
  const listnerRef = useRef(null);
  const selectedEvent = useSelector((state) => state.scanner.selectedEvent);
  const customer = useSelector((state) => state.scanner.customer);
  const [rfidError, setRFIDError] = useState();

  useEffect(() => {
    document.getElementById("mainBox").style.overflowY = "hidden";
    document.body.style.overflowY = "hidden";
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsStartScanning(true);
      clearTimeout(timer);
    }, 200);

    if (listnerRef.current) {
      document.getElementById("rfid-input").focus();
      listnerRef.current.addEventListener("click", () => {
        document.getElementById("rfid-input").focus();
      });
      listnerRef.current.addEventListener("touchend", () => {
        document.getElementById("rfid-input").focus();
      });
    }
  }, [listnerRef]);

  const socketName = useMemo(() => {
    if (selectedEvent && scanner && scanner.data) {
      return `${selectedEvent.code}:${scanner.data.code}:DEVICE`;
    }
    return null;
  }, [selectedEvent, scanner]);

  const socketCallback = useCallback(
    (data) => {
      if (data.qrCode === false) {
        history.push("/welcome");
      }
    },
    [history]
  );

  useSocketIO({ socketCallback, socketName });

  const handleChangeInput = useCallback(
    (event) => {
      if (isStartScanning) setrfidValue(event.target.value);
    },
    [isStartScanning]
  );

  useEffect(() => {
    dispatch(actions.customer.resetCustomer());
  }, [dispatch]);

  useEffect(() => {
    if (customer && customer.data) {
      history.push("/customer-details");
    }

    if (customer && customer.error) {
      dispatch(
        toasterActions.toaster.set({
          variant: "danger",
          message: customer.error,
        })
      );
      setIsSubmitting(false);
      dispatch(actions.customer.resetCustomer());
    }
  }, [dispatch, history, customer, debouncerfid.length]);

  useEffect(() => {
    if (!selectedEvent) {
      history.replace("/events");
    }
  }, [history, selectedEvent]);

  useEffect(() => {
    if (isSubmitting) {
      setError(null);
      let hexString = "";
      if (!debouncerfid.includes("!")) hexString = decimalToHexa(debouncerfid);
      else
        hexString = decimalToHexa(debouncerfid)
          .match(/.{1,2}/g)
          .reverse()
          .toString()
          .replaceAll(",", "");
      let final_code =
        hexaDecimalToDeicmal(hexString).toString() !== "NaN"
          ? hexaDecimalToDeicmal(hexString).toString()
          : debouncerfid;

      if (scanner && scanner.data && final_code && selectedEvent) {
        const dataBody = {
          payload: {
            customerCode: final_code.trim(),
            gateCode: scanner.data.code,
            rfid: true,
          },
          eventCode: selectedEvent.code.trim(),
        };
        dispatch(actions.customer.search(dataBody));
      }
    }
  }, [isSubmitting, debouncerfid, dispatch, history, scanner, selectedEvent]);

  const handleCheckRFID = useCallback(async () => {
    if (debouncerfid && debouncerfid.length >= 7) {
      setIsSubmitting(true);
    } else {
      setError("Min characters is 7");
    }
  }, [debouncerfid]);

  useEffect(() => {
    if (debouncerfid && debouncerfid.length >= 7) handleCheckRFID();
    if (debouncerfid && debouncerfid.length < 7) setRFIDError("Invalid code");
  }, [debouncerfid, handleCheckRFID, setError]);

  return (
    <>
      <div
        ref={listnerRef}
        className="position-absolute top-50 start-50 translate-middle w-100"
        style={{
          height: "100%",
        }}
      >
        <div className="row mx-0 padding position-absolute top-50 start-50 translate-middle">
          <div className="d-flex flex-column">
            <SectionTitle name="Scan RFID" />
            <div className="mt-5 w-100">
              <InputField
                disabled={isSubmitting}
                id="rfid-input"
                type="text"
                value={rifdValue}
                placeholder="RFID Code"
                onChange={(e) => handleChangeInput(e)}
              />

              {error && (
                <p
                  className="text-center mt-2"
                  style={{
                    color: "red",
                  }}
                >
                  {error}
                </p>
              )}
              {rfidError && (
                <p
                  className="text-center mt-2"
                  style={{
                    color: "red",
                  }}
                >
                  {rfidError}
                </p>
              )}

              <div className="text-center mt-3">
                <CustomButton
                  disabled={!rifdValue || isSubmitting}
                  isSubmitting={isSubmitting}
                  type="button"
                  onClick={handleCheckRFID}
                  label="submitGate"
                  title="Next"
                >
                  Next
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      {scanner && scanner.data && selectedEvent && (
        <Footer
          path={`${scanner.data.name}/${selectedEvent.name}/welcome`}
          onClick={() => {
            setTimeout(() => {
              history.push("/welcome");
            }, 500);
          }}
        />
      )}

      <div
        className="position-fixed bottom-0 end-0 m-3 scan-with-qrcode-or-rfid"
        onClick={() => {
          history.push("/badge-collection-scanner");
        }}
      >
        Scan with QRCode
      </div>
    </>
  );
};

export const decimalToHexa = (number) => {
  return parseInt(number).toString(16);
};

export const hexaDecimalToDeicmal = (code) => {
  return parseInt(code, 16);
};
